<template>
    <div>
        <edit-rsa v-if="isEditing" :rsa="rsa" @cancel="reset"/>
    </div>
</template>

<script>
    import EditRsa from './edit-rsa';

    export default {
        name: 'about-us',
        components: { EditRsa },
        data() {
            return {
                isEditing: false,
                rsa: {
                    roadside_assistance_text: { en: '', ar: '' },
                    roadside_assistance_title: { en: '', ar: '' },
                    roadside_assistance_image: []
                }
            };
        },
        methods: {
            reset(){
                this.isEditing = false;
                this.getData();
            },
            getData() {
                this.$vs.loading();
                this.$httpAbout.get('/config/rsa')
                    .then(r => {
                        this.rsa = r.data.data;
                        //console.log(this.rsa);
                        this.$vs.loading.close();
                        this.isEditing = true;
                    })
                    .catch(() => {
                        //console.log(e);
                        this.$vs.notify({
                            title: 'Error',
                            text: 'Couldn\'t Load Data!',
                            color: 'danger'
                        });
                        this.$vs.loading.close();
                    });
            }
        },
        mounted() {
            this.getData();
        }
    };
</script>

<style scoped lang="scss">

</style>
